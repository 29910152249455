@import "initialize";

.center-image
{
    margin: 0 auto;
    display: block;
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  margin-bottom: 10px;
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.btn-gitter .icon {
  opacity: 0.6;
  background: url("../images/gitter.png") 0 0 no-repeat; 
}

.btn-synwall .icon {
  opacity: 0.6;
  background: url("../images/SYNwall-SCP-mini.png") 0 0 no-repeat;
}
